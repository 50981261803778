/* eslint-disable */

/**
 * 数据库保存的字符串bool值: true
 * @type {string}
 */
export var BOOL_VALUE_TRUE = 'true';
/**
 * 字典：yes_no 是否
 * @type {number}
 */
// 是

export var BOOL_VALUE_YES = 1; // 否

export var BOOL_VALUE_NO = 2;
/**
 * 字典：is_enable 是否启用
 * @type {number}
 */
// 启用

export var ENABLE_STATUS_ON = 1; // 禁用

export var ENABLE_STATUS_OFF = 0;
/**
 * 字典：cycle_unit 周期单位
 * @type {number}
 */
// 日

export var CYCLE_UNIT_DAY = 1; // 周

export var CYCLE_UNIT_WEEK = 2; // 月

export var CYCLE_UNIT_MONTH = 3; // 年

export var CYCLE_UNIT_YEAR = 4;
/**
 * 字典：base_resource_type_all 巡检资源类型
 * @type {number}
 */
// 楼栋

export var BASE_RESOURCE_TYPE_BUILDING = 1; // 楼层

export var BASE_RESOURCE_TYPE_FLOOR = 2; // 房间

export var BASE_RESOURCE_TYPE_ROOM = 3; // 道路

export var BASE_RESOURCE_TYPE_ROAD = 4; // 湖泊

export var BASE_RESOURCE_TYPE_LAKE = 5; // 绿化区

export var BASE_RESOURCE_TYPE_GREENAREA = 6; // 运动场

export var BASE_RESOURCE_TYPE_PLAYGROUND = 7; // 出入口/门

export var BASE_RESOURCE_TYPE_PASSAGEWAY = 8; // 垃圾收集点

export var BASE_RESOURCE_TYPE_RUBBISH_COLLETION = 9; // 垃圾清运点

export var BASE_RESOURCE_TYPE_RUBBISH_CLEANING = 10; // 设备

export var BASE_RESOURCE_TYPE_DEVICE = 11; // 古树

export var BASE_RESOURCE_TYPE_OLD_TREE = 12; // 路灯

export var BASE_RESOURCE_TYPE_STREETLIGHT = 13;
/**
 * 字典：inspect_point_task_inspect_status 巡检点任务巡检情况
 * @type {number}
 */
// 正常

export var INSPECT_TASK_INSPECT_STATUS_INNORMAL = 1; // 异常（生成工单）

export var INSPECT_TASK_INSPECT_STATUS_UNNORMAL = 2; // 异常（不生成工单）

export var INSPECT_TASK_INSPECT_STATUS_UNNORMAL_TOO = 3;
/**
 * 字典：inspect_order_biz_status 巡检工单业务状态
 * @type {string}
 */
// 待处理

export var INSPECT_ORDER_BIZ_STATUS_TODO = 'todo'; // 处理中

export var INSPECT_ORDER_BIZ_STATUS_DOING = 'doing'; // 已完成

export var INSPECT_ORDER_BIZ_STATUS_FINISHED = 'done'; // 已取消

export var INSPECT_ORDER_BIZ_STATUS_CANCELED = 'cancel';
/**
 * 字典：plan_type 计划类型
 * @type {number}
 */
// 活动

export var PLAN_TYPE_ACTIVE = 1;
/**
 * 字典：plan_task_status 计划任务状态/活动状态(值同巡检任务状态)
 * @type {number}
 */
// 未开始

export var PLAN_TASK_STATUS_TODO = 1; // 进行中

export var PLAN_TASK_STATUS_DOING = 6; // 已结束

export var PLAN_TASK_STATUS_FINISHED = 3; // 已取消

export var PLAN_TASK_STATUS_CANCELED = 4;
/**
 * 字典：fixed_asset_room_use_type 固定资产房间使用类型
 * @type {number}
 */
// 长期

export var FIXED_ASSET_ROOM_USE_TYPE_LONG = 1; // 短期

export var FIXED_ASSET_ROOM_USE_TYPE_SHORT = 2;
/**
 * 字典：fixed_asset_room_use_status 固定资产房间使用状态
 * @type {number}
 */
// 正常

export var FIXED_ASSET_ROOM_USE_STATUS_INNORMAL = 1; // 过期

export var FIXED_ASSET_ROOM_USE_STATUS_EXPIRED = 2; // 未使用

export var FIXED_ASSET_ROOM_USE_STATUS_NOT_USED = 3;
/**
 * 字典：fixed_asset_classify_tree_type 固定资产节点类型
 * @type {number}
 */
// 文件夹

export var FIXED_ASSET_CLASSIFY_TREE_TYPE_FOLDER = 1; // 分类

export var FIXED_ASSET_CLASSIFY_TREE_TYPE_CLASSIFY = 2;
/**
 * 字典：fixed_asset_classify_detail_task_status 固定资产分类详情巡检任务状态
 * @type {number}
 */
// 未开始

export var FIXED_ASSET_CLASSIFY_DETAIL_TASK_STATUS_TODO = 1; // 进行中

export var FIXED_ASSET_CLASSIFY_DETAIL_TASK_STATUS_DOING = 2; // 已结束

export var FIXED_ASSET_CLASSIFY_DETAIL_TASK_STATUS_FINISHED = 3; // 已取消

export var FIXED_ASSET_CLASSIFY_DETAIL_TASK_STATUS_CANCELED = 4;
/**
 * 档案类型
 * @type {number}
 */
// 分类

export var ARCHIVES_TREE_TYPE_CLASSIFY = 0; // 文件夹

export var ARCHIVES_TREE_TYPE_FOLDER = 1;